import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import InterTransfer from "../components/robot/pages/InterTransferPage"


// markup
const Login = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <InterTransfer />
        </React.Fragment>
    )
}

export default Login
