import { Modal, Spin } from "antd";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";

const ConfirmTransferModal = (props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  function close() {
    if (props.onClose) {
      props.onClose();
    } else {
      setVisible(false);
    }
  }

  function handleSubmit(s) {
    if (props.onSubmit) {
      props.onSubmit();
    } else {
      setVisible(false);
    }
  }

  const transferObject = props.transferObject ? props.transferObject : {};

  return (
    <React.Fragment>
      <Modal
        className="no-padding-modal-body modal-body-background-transparent"
        centered
        maskClosable
        mask
        footer={null}
        closable={false}
        visible={visible}
        onCancel={() => {
          close();
        }}
      >
        <Spin spinning={props.loading}>
          <div
            className="lightgreybox-bg-color rounded-3xl p-10 pt-5 px-5"
            style={{ borderRadius: 15 }}
          >
            <div className="inline-block text-lg font-semibold mb-10 flex justify-center ">
              {t("confirmTransfer", sourceKey.asset)}
            </div>
            <React.Fragment>
              <div className="flex justify-start items-center mb-3">
                <span className="inline-block w-1/3 lg:w-1/4 text-base  font-semibold mr-3">
                  {/* senderReferralCode UID: */}
                  {t("senderReferralCode", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 lg:w-3/4 text-base  font-semibold ">
                  {get(transferObject, "senderId")
                    ? get(transferObject, "senderId")
                    : ""}
                </span>
              </div>

              <div className="flex justify-start items-center mb-3">
                <span className="inline-block w-1/3 lg:w-1/4 text-base  font-semibold mr-3">
                  {t("senderName", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 lg:w-3/4 text-base  font-semibold ">
                  {get(transferObject, "senderName")
                    ? get(transferObject, "senderName")
                    : ""}
                </span>
              </div>

              {/* <div className="flex justify-start items-center mb-3">
                <span className="inline-block w-1/3 lg:w-1/4 text-base  font-semibold mr-3">
                  {t("receiverReferralCode", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 lg:w-3/4 text-base  font-semibold ">
                  {get(transferObject, "receiverId")
                    ? get(transferObject, "receiverId")
                    : ""}
                </span>
              </div> */}

              <div className="flex justify-start items-center mb-3">
                <span className="inline-block w-1/3 lg:w-1/4 text-base  font-semibold mr-3">
                  {t("receiverName", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 lg:w-3/4 text-base  font-semibold ">
                  {get(transferObject, "receiverName")
                    ? get(transferObject, "receiverName")
                    : ""}
                </span>
              </div>

              <div className="flex justify-start items-center mb-3">
                <span className="inline-block w-1/3 lg:w-1/4 text-base  font-semibold mr-3">
                  {/* Transfer Amount: */}
                  {t("transferAmount", sourceKey.asset)}
                </span>
                <span className="inline-block w-2/3 lg:w-3/4 text-base  font-semibold ">
                  {get(transferObject, "amount")
                    ? get(transferObject, "amount")
                    : ""}{" "}
                  {" USDT"}
                </span>
              </div>
            </React.Fragment>
            <div className="flex justify-around mt-5">
              <span
                className=" px-3 py-1.5 border second-button-color rounded-md"
                onClick={close}
              >
                {t("cancel")}
              </span>
              <span
                className="rounded-md main-button-color px-3 py-1.5"
                onClick={handleSubmit}
              >
                {t("confirm")}
              </span>
            </div>
          </div>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmTransferModal;
