import axios from "axios";
import { get, isEmpty } from "lodash";
import client from "../../../feathers";
import { routePrefix } from "../index";

export default async function getSystemConfiguration(accessKey, key) {
  let matchKey = "";

  if (!isEmpty(key)) {
    matchKey = "?key=".concat(key);
  }

  try {
    return await axios
      .get(`${client.io.io.uri}${routePrefix.systemConfiguration}${matchKey}`, {
        headers: {
          Authorization: `Bearer ${accessKey}`,
        },
      })
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (err) {
    return new Promise((resolve, reject) => {
      reject({
        errorType: get(err, "response.data.errorType") || "api_error",
        message: get(err, "response.data.message") || "API Error",
        err: err,
      });
    });
  }
}
