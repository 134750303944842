import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  FileProtectOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, InputNumber, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby-link";
import {
  includes,
  isEmpty,
  isEqual,
  isInteger,
  isNaN,
  toLower,
  trim,
} from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import transferUserWallet from "../../../newApi/pairing/transferUserWallet";
import getProfiles from "../../../newApi/profile/getProfiles";
import getSystemConfiguration from "../../../newApi/systemConfiguration/getSystemConfiguration";
import validatePassword from "../../../newApi/user/validatePassword";
import { routes } from "../../../route";
import { formatNumber } from "../../../utilities/common-function";
import { verifyGoogle2faCode } from "../../../utilities/google-2fa-qr";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import ConfirmTransferModal from "../../robot/components/ConfirmTransferModal";
import Required2FAWrapper from "../components/Required2FAWrapper";
import RequiredPaymentPasswordWrapper from "../components/RequiredPaymentPasswordWrapper";

const InterTransfer = (props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [keyInUser, setKeyInUser] = useState("");
  const [accountInput, setAccountInput] = useState("");
  const [senderId, setSenderId] = useState("");
  const user = get(props, "user.user");
  const [balanceUsdt, setBalanceUsdt] = useState(0);
  const accessKey = get(props, "user.accessKey");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [senderName, setSenderName] = useState("");
  const [senderUID, setSenderUID] = useState("");
  const [confirmData, setConfirmData] = useState({});
  const [transferDescription, setTransferDescription] = useState("");
  const [receiverInfo, setReceiverInfo] = useState({});
  const [disableWithdraw, setDisableWithdraw] = useState(false);
  let receiverName = "";

  //get sender's profileId when page loads
  useEffect(() => {
    setSenderId(get(user, "profile._id"));
    setSenderName(get(user, "username"));
    setSenderUID(get(user, "referralCode"));
    getUsdtBalanceAPI();
    getTransferDescriptionAPI();
    check2FAStatus();
  }, []);

  function check2FAStatus() {
    if (isEmpty(get(user, "secretKey"))) {
      //Google 2FA not set up
      // message.loading(
      //   "您未设置谷歌验证. 正在跳转谷歌验证设置页...   You have not set up Google 2FA. Redirecting to Google 2FA setup page...",
      //   3
      // );
      message.loading(t("setUp2FA", sourceKey.asset), 3);
      setTimeout(() => {
        navigate(routes.setting.to({ type: "2FA" }));
      }, 3000);
    }
  }

  function getUsdtBalanceAPI() {
    getProfiles(1, 0, { 
      // _id: get(user, "profile._id") 
    }).then((res) => {
      let balance = parseFloat(get(res, "data[0].pairingProfile.wallet")) || 0;
      let data = res?.data;
      data = data[0];
      if (data?.freezeWallet === 1) {
        setDisableWithdraw(true);
      }
      setBalanceUsdt(balance);
    });
  }

  function getTransferDescriptionAPI() {
    getSystemConfiguration(accessKey, "transferDescription").then((res) => {
      setTransferDescription(get(res, "result[0].transferDescription"));
    });
  }

  function openConfirmModal() {
    form
      .validateFields()
      .then((values) => {
        if (isEqual(senderName, accountInput)) {
          message.error(t("cannotTransfetoSelf", sourceKey.asset));
        } else {
          // getProfiles(1, 0, { username: toLower(accountInput) }).then((res) => {
          // console.log("receiver info", res);
          // let receiver_data = get(res, "data");
          // receiver_data = receiver_data[0];
          // setReceiverInfo(receiver_data);
          // receiverName = get(
          //   get(get(res, "data[0]"), "populatedUserId"),
          //   "username"
          // );
          setConfirmData({
            amount: Number(values.amount),
            senderId: senderUID,
            receiverId: accountInput,
            senderName: senderName,
            receiverName: accountInput,
          });

          // if (isEmpty(receiverName)) {
          //   message.error(t("receiverIdnotFound", sourceKey.asset));
          // } else
          if (
            Number(values.amount) < 10 ||
            Number(values.amount) > balanceUsdt
          ) {
            message.error(t("transferError", sourceKey.asset));
          } else {
            validatePassword(get(user, "username"), values.password, accessKey)
              .then((res) => {
                if (isEqual(get(res, "message"), "User password is correct")) {
                  if (
                    verifyGoogle2faCode(
                      get(user, "secretKey"),
                      values.checkCode
                    )
                  ) {
                    setIsModalVisible(true);
                  } else {
                    // message.error("Google 2FA Code is invalid");
                    message.error(t("invalid2FA", sourceKey.asset));
                  }
                } else {
                  message.error(t("invalidPassword"));
                }
              })
              .catch((err) => {
                message.error(err.message);
              });
          }
          // });
        }
      })
      .catch((err) => {
        message.error(t("keyInAllInfo", sourceKey.asset));
        setIsModalVisible(false);
        setLoading(false);
      });
  }

  const cancelHandler = () => {
    setIsModalVisible(false);
  };

  function handleSubmit() {
    let transferObject = {};
    // console.log(receiverInfo);
    // console.log(get(user, "profile.pairingUserId"));
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        transferObject = {
          amount: Number(values.amount),
          senderId: senderId,
          receiverId: get(receiverInfo, "_id"),
        };

        // console.log(transferObject);

        transferUserWallet({
          // userId: get(user, "profile.pairingUserId"),
          receiverName: accountInput,
          amount: Number(values.amount),
        }).then((res) => {
          form.resetFields();
          getUsdtBalanceAPI();
          setIsModalVisible(false);
          setLoading(false);
          message
            .success(t("transferComplete", sourceKey.asset), 1.5)
            .then(() => {
              message
                .loading(t("redirectTransfer", sourceKey.asset), 1.5)
                .then(() => {
                  navigate(routes.transferList.to());
                });
            })
            .catch((err) => {
              message.error(get(err, "message"));
              console.log(err);
              setIsModalVisible(false);
              setLoading(false);
            });
        })
        .catch((err)=>{
          message.error(err?.message);
          setIsModalVisible(false);
          setLoading(false);
        })
      })
      .catch((err) => {
        message.error(t("transferFailed", sourceKey.asset));
        setIsModalVisible(false);
        setLoading(false);
      });
  }

  const _renderTips = () => {
    return (
      <div className="mt-5">
        <div className=" text-sm font-semibold">
          {t("tips", sourceKey.asset)}
        </div>
        <br />

        <textarea
          disabled
          className="w-full"
          style={{ height: "200px" }}
          value={transferDescription}
        ></textarea>
      </div>
    );
  };

  function onChangeReceiver(e) {
    // console.log(toLower(e.target.value?.trim()));
    form.setFieldsValue({
      userId: toLower(e.target.value?.trim()),
    });
    setAccountInput(toLower(e.target.value?.trim()));
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {t("transfer", sourceKey.asset)}
                </div>
              </React.Fragment>
            }
            after={
              <FileProtectOutlined
                className="black  mx-2"
                style={{ fontSize: 23 }}
                onClick={() => navigate(routes.transferList.to())}
              />
            }
            showBack={true}
          >
            <RequiredPaymentPasswordWrapper />
            <Required2FAWrapper />
            {disableWithdraw === true && (
              <>
                <div className="px-6 mt-2 ">
                  ***
                  <span className="text-green-400">
                    {" "}
                    {t("freezeWallet", sourceKey.asset)}{" "}
                  </span>
                  ***
                </div>
                <div className="px-6 mb-2">
                  <span>
                    {" "}
                    {t("click", sourceKey.asset)}{" "}
                    <span
                      className="text-blue-400 underline cursor-pointer"
                      onClick={() => {
                        navigate(routes.customerService.to());
                      }}
                    >
                      {t("here", sourceKey.asset)}
                    </span>{" "}
                  </span>
                  <span>{t("forContact", sourceKey.asset)}</span>
                </div>
              </>
            )}
            <Form form={form} style={{ color: "black" }}>
              <div className="px-6 py-5">
                <div className="mt-3">{t("receiverUid", sourceKey.asset)}</div>
                <div className="rounded-3xl mt-1">
                  <Form.Item
                    name="userId"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请填写接收 UID <br />
                            Please fill in your UID */}
                            {t("fillInReceiverUid", sourceKey.asset)}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("fillInReceiverUid", sourceKey.asset)}
                      className={"input-border"}
                      onChange={onChangeReceiver}
                      value={accountInput}
                      disabled={disableWithdraw}
                    />
                  </Form.Item>
                </div>
                <div className="mt-3">
                  {/* {keyInUser} */}

                  {t("transferAmount", sourceKey.asset)}
                </div>

                <Form.Item
                  name="amount"
                  initialValue={0}
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (!value) {
                          callback(
                            <React.Fragment>
                              {t("transferAmountRequired", sourceKey.asset)}
                            </React.Fragment>
                          );
                        } else if (isNaN(parseFloat(value))) {
                          callback(
                            <React.Fragment>
                              {/* 请输入数字 <br />
                              Please insert number */}
                              {t("insertNumber", sourceKey.asset)}
                            </React.Fragment>
                          );
                        } else if (value < 10) {
                          callback(
                            <React.Fragment>
                              {t("minTen", sourceKey.asset)}
                            </React.Fragment>
                          );
                        } else if (
                          (!isEmpty(value) && !isInteger(parseFloat(value))) ||
                          includes(value, ".")
                        ) {
                          callback(
                            <React.Fragment>
                              {t("noDecimal", sourceKey.asset)}
                            </React.Fragment>
                          );
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                >
                  <InputNumber
                    formatter={(value) =>
                      isNaN(parseInt(value)) ? 0 : parseInt(value)
                    }
                    placeholder=""
                    addonAfter="USDT"
                    className="w-full"
                    disabled={disableWithdraw}
                  />
                </Form.Item>
                <div className="text-xs flex mt-1">
                  {t("availableBalance", sourceKey.asset)}:{" "}
                  {formatNumber(balanceUsdt, null, true, 8, true)}
                </div>
                <div className="rounded-xl mt-1"></div>

                <div className="mt-3">
                  {t("loginPassword", sourceKey.profile)}
                </div>
                <div className="flex items-center my-3">
                  <span className="inline-block w-full">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {t("loginPasswordRequired", sourceKey.asset)}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={t(
                          "loginPasswordRequired",
                          sourceKey.asset
                        )}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        className={"input-border"}
                        disabled={disableWithdraw}
                      />
                    </Form.Item>
                  </span>
                </div>
                <div className="mt-3">{t("googleAuth", sourceKey.asset)}</div>
                <div className="flex items-center my-3">
                  <span className="inline-block w-full">
                    <Form.Item
                      style={{ margin: 0 }}
                      name="checkCode"
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {t("googleAuthRequired", sourceKey.asset)}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder="XXXXXX"
                        autoComplete="off"
                        className={"input-border"}
                        disabled={disableWithdraw}
                      />
                    </Form.Item>
                  </span>
                </div>

                <div className="rounded-l mt-8">
                  <Button
                    block
                    disabled={disableWithdraw}
                    className="main-button-color border button-border"
                    onClick={(e) => {
                      openConfirmModal();
                    }}
                  >
                    {t("transfer", sourceKey.asset)}
                  </Button>
                </div>
                {_renderTips()}
              </div>
            </Form>

            <ConfirmTransferModal
              visible={isModalVisible}
              onClose={cancelHandler}
              transferObject={confirmData}
              onSubmit={handleSubmit}
              loading={loading}
            />
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(InterTransfer);
